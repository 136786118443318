// import React from "react";
// import "./PrivacyPolicy.css";

// const PrivacyPolicy = () => {
//   return (
//     <div className="privacy-policy-container">
//       <h1>Privacy Policy</h1>
//       <p>This is the privacy policy content. You can add your own text here.</p>
//     </div>
//   );
// };

// export default PrivacyPolicy;

import React from "react";
import "./PrivacyPolicy.css"; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>
        Effective Date: [Insert Date] Somerco Research Ltd. ("we," "our," or
        "us") is committed to protecting your privacy. This Privacy Policy
        outlines our practices concerning the collection, use, and disclosure of
        information when you use our websites Bestest Besties and Coders Aid
        Fund (the "Sites"). This policy applies to both: Bestest Besties:
        [Insert Bestest Besties Website URL] Coders Aid Fund: [Insert Coders Aid
        Fund Website URL] By accessing or using the Sites, you agree to the
        terms of this Privacy Policy.
      </p>
      <div className="privacy-section">
        <h2>1. Information Collection</h2>
        <p>
          We collect several types of information to provide and improve our
          services to you: a. Personal Information We may collect personal
          information that you provide to us, such as your name, email address,
          phone number, and any other information you voluntarily provide when
          you sign up for our newsletters, make a purchase, or contact us. b.
          Usage Data We may collect information on how the Sites are accessed
          and used. This usage data may include information such as your
          computer's IP address, browser type, browser version, the pages of our
          Sites that you visit, the time and date of your visit, and other
          diagnostic data. c. Cookies and Tracking Data We use cookies and
          similar tracking technologies to track the activity on our Sites and
          hold certain information. Cookies are files with a small amount of
          data that may include an anonymous unique identifier.
        </p>
      </div>
      <div className="privacy-section">
        <h2>2. Use of Information</h2>
        <p>
          We use the collected data for various purposes: To provide and
          maintain our Sites. To notify you about changes to our services. To
          allow you to participate in interactive features of our Sites when you
          choose to do so. To provide customer support. To gather analysis or
          valuable information so that we can improve our Sites. To monitor the
          usage of our Sites. To detect, prevent, and address technical issues.
        </p>
      </div>
      <div className="privacy-section">
        <h2>3. Sharing of Information</h2>
        <p>
          We may disclose your personal information in the following
          circumstances: With your consent: We may share your information with
          third parties when you have given us explicit consent to do so.
          Service Providers: We may employ third-party companies and individuals
          to facilitate our services ("Service Providers"), to provide the
          service on our behalf, to perform Site-related services, or to assist
          us in analyzing how our Sites are used. Legal Requirements: We may
          disclose your personal information if required to do so by law or in
          response to valid requests by public authorities.
        </p>
      </div>
      <div className="privacy-section">
        <h2>4. Data Security</h2>
        <p>
          The security of your personal information is important to us, and we
          strive to implement and maintain reasonable, commercially acceptable
          security procedures and practices appropriate to the nature of the
          information we store to protect it from unauthorized access,
          destruction, use, modification, or disclosure.
        </p>
      </div>
      <div className="privacy-section">
        <h2>5. Links to other sites</h2>
        <p>
          Our Sites may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party's site.
        </p>
      </div>
      <div className="privacy-section">
        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this privacy policy from time to time. We will notify
          you of any changes by posting the new policy on our website.
        </p>
      </div>
      <p className="last-updated">Last Updated: August 12, 2024</p>
    </div>
  );
};

export default PrivacyPolicy;
