import React from "react";
import NFTbackground5 from "../images/NFTbackground5.jpg";
import Partner1Image from "../images/Partner1.jpg";
import Partner2Image from "../images/Partner2.jpg";
import Partner3Image from "../images/Partner3.png";
import Partner4Image from "../images/Partner4.jpeg";
import "./Partners.css";

const Partners = () => {
  return (
    <div
      className="partners-container"
      style={{ backgroundImage: `url(${NFTbackground5})` }}
    >
      <h1>Partners</h1>
      <div className="partners-content">
        <div className="partner">
          <img src={Partner1Image} alt="Partner 1" className="partner-image" />
          <div className="partner-text">
            <h2>SuperteamUK</h2>
            <p>
              Superteam is the Talent Layer of Solana. You can join a community
              of the best talent learning, earning and building in crypto.
            </p>
          </div>
        </div>
        <div className="partner">
          <img src={Partner2Image} alt="Partner 2" className="partner-image" />
          <div className="partner-text">
            <h2>Solana Foundation</h2>
            <p>
              The Solana Foundation is a non-profit foundation based in Zug,
              Switzerland, dedicated to the decentralization, adoption, and
              security of the Solana ecosystem.
            </p>
          </div>
        </div>
        <div className="partner">
          <img src={Partner3Image} alt="Partner 3" className="partner-image" />
          <div className="partner-text">
            <h2>Code Your Future</h2>
            <p>
              We partner with CodeYourFuture a UK-based non-profit organisation
              that trains some of the most deprived members of society to gain
              the tech skills they need to succeed. After the sale of our NFT
              collection Bestest Besties we will donate $25 000 to this charity.
            </p>
          </div>
        </div>
        <div className="partner">
          <img src={Partner4Image} alt="Partner 4" className="partner-image" />
          <div className="partner-text">
            <h2>Next Tech Girls</h2>
            <p>
              We partner with Next Tech Girls that smash stereotypes to inspire
              today's girls to become tomorrow's women in tech. After the sale
              of our NFT collection Bestest Besties we will donate $25 000 to
              this charity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
