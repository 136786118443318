import React from "react";
import NFTbackground1 from "../images/NFTbackground1.jpg";
import NFTvoiceBB from "../images/NFTvoiceBB.mp4";
import "./Home.css";

const Home = () => {
  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${NFTbackground1})` }}
    >
      <h1>The Bestest Besties Collection</h1>
      <div className="home-content">
        {/* Updated: Replace img with video */}
        <video
          className="home-video"
          src={NFTvoiceBB}
          controls
          autoPlay
          muted
          loop
        />
        <p className="home-text-block">
          <h2>
            Quote from the jungle: “When life goes bananas, keep a pear close.”
          </h2>
          <br />
          <br />
          Welcome to The Bestest Besties Collection: Get ready to meet the most
          spirited duo in the NFT jungle: funky bananas and heart-throb pears!
          <br />
          <br />
          They’re quirky, they’re funky, and they’re here to make a difference.
          Each unique piece in this collection is a token of support for a
          greater cause.
          <br />
          <br />
          The Coders Aid Fund is our mission: raising funds to help people from
          all walks of life become coders and creators. Whether you’re a
          seasoned developer, a blockchain enthusiast, or just someone who loves
          cool art with a purpose, this collection has something for you.
          <br />
          <br />
          Why bananas and pears? Because life gets a little wild sometimes, and
          what better way to celebrate the chaos than with funky bananas that
          are here to remind you to stay playful, and heart-throb pears to keep
          your heart in the right place as you help empower the next wave of
          coders.
          <br />
          <br />
          Join the movement: By owning a piece of the Bestest Besties
          Collection, you’re not just adding a funky flair to your wallet—you’re
          joining a community of change-makers. Your contribution helps build
          opportunities, fund education, and shape futures, one coder at a time.
          <br />
          <br />
          Collect. Laugh. Code. Inspire.
          <br />
          <br />
          Step into the jungle, embrace the funk, and let’s make a difference
          together—with bananas, pears, and a whole lot of heart.
        </p>
      </div>
    </div>
  );
};

export default Home;
